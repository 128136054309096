import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
//import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                {/** Qué es¿? */}
                <Title></Title>
                <Text>
                    Arriba la segona edició!
                    <br />
                    <br />
                    Del seminari presencial de la Llei de Seguretat Ciutadana i del Codi Penal.
                    <br />
                    <br />
                    Sis hores de formació continuada, amb explicacions detallades de La Llei 4/2015
                    i del codi penal.
                    <br />
                    <br />
                    Coneix les infraccions més importants i els delictes que més es treballen en el
                    món policial.
                    <br />
                    <br />
                    Dissabte 21 de gener de 2023
                    <br />
                    <br />
                    De 9.00 a 15.00 h
                    <br />
                    <br />
                    6 hores de seminari
                    <br />
                    <br />
                    Dossier específic per a tots els assistents.
                    <br />
                    <br />
                    Una formació específica per al curs bàsic de l'Institut de Seguretat Pública de
                    Catalunya i les pràctiques bulevard.
                    <br />
                    <br />
                    El seminari s' imparteix a Hospitalet.
                    <br />
                    <br />
                    Al c/ Marti Codolar núm 18.
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
